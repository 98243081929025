@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./transitions";

@font-face {
  font-family: "Gordita";
  src: url("/fonts/Gordita_Bold.woff2") format("woff2"),
    url("/fonts/Gordita_Bold.woff") format("woff"),
    url("/fonts/Gordita_Bold.otf") format("opentype");
  font-style: bold;
  font-weight: 700;
}

@font-face {
  font-family: "Gordita";
  src: url("/fonts/Gordita_Medium.woff2") format("woff2"),
    url("/fonts/Gordita_Medium.woff") format("woff"),
    url("/fonts/Gordita_Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Gordita";
  src: url("/fonts/Gordita_Regular.woff2") format("woff2"),
    url("/fonts/Gordita_Regular.woff") format("woff"),
    url("/fonts/Gordita_Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

:root {
  --plyr-color-main: #fa4517;
  --plyr-font-size-base: 20px;
}

@media (min-width: 1024px) {
  .plyr__captions .plyr__caption {
    font-size: 28px !important;
  }
}
